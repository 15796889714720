import { Controller } from "stimulus";
import Body from './../components/body';

const CLASS_OPENED = 'is-menu-opened';
const CLASS_HEADER_SCROLLED = 'is-header-scrolled';
const CLASS_ALERT_ACTIVE = 'is-alert-active';

export default class extends Controller {
    static targets = ['header', 'menu', 'menuModal', 'menuModalWrap', 'content', 'burger', 'video'];
    opened = false;
    isHeaderScrolled = false;
    isAlertActive = false;
    scrollHeight = null;
    videoTimeoutId = null;

    initialize() {
        this.onScroll = this.onScroll.bind(this);
        this.tick = this.tick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    connect() {
        this.body = new Body();
        this.body.addOnScroll(this.onScroll);
        this.tick();
        this.onScroll();
        // this.openMenu();
        window.addEventListener('resize', this.tick);
        // this.menuModalTarget.addEventListener('click', this.outsideClickListener);
        this.contentTarget.addEventListener('click', this.outsideClickListener);
    }

    // outsideClickListener(e) {
    //     if (!this.menuModalWrapTarget.contains(e.target)) { 
    //         this.closeMenu();
    //     }
    // }

    outsideClickListener(e) {
        if (!this.burgerTarget.contains(e.target)) { 
            this.closeMenu();
        }
    }

    // Update amount to scroll past header
    // Update menu height
    tick() {
        this.scrollHeight = this.headerTarget.clientHeight;
        this.bodyHeight = this.body.dom.clientHeight;
        this.wh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        // console.log(this.bodyHeight);
    }

    // Header to scroll (and show logo on menu)
    onScroll() {
        // Scrolled past header
        if (window.pageYOffset > this.scrollHeight) this.headerScrolled();
        else this.headerUnscrolled();

        if (window.pageYOffset > this.bodyHeight - this.wh - 400) this.alertDeactivate();
        else this.alertActivate();
    }

    alertActivate() {
        if (this.isAlertActive) return;
        this.isAlertActive = true;
        this.body.dom.classList.add(CLASS_ALERT_ACTIVE);
    }

    alertDeactivate() {
        if (!this.isAlertActive) return;
        this.isAlertActive = false;
        this.body.dom.classList.remove(CLASS_ALERT_ACTIVE);
    }

    headerScrolled() {
        if (this.isHeaderScrolled) return;
        this.isHeaderScrolled = true;
        this.body.dom.classList.add(CLASS_HEADER_SCROLLED);
    }

    headerUnscrolled() {
        if (!this.isHeaderScrolled) return;
        this.isHeaderScrolled = false;
        this.body.dom.classList.remove(CLASS_HEADER_SCROLLED);
    }


    // Open Menu
    openMenu() {
        if (this.opened) return;
        this.opened = true;

        if (this.hasVideoTarget) {
            clearTimeout(this.videoTimeoutId);
            this.videoTarget.pause();
        }

        this.element.classList.add(CLASS_OPENED);
        this.body.disableScroll();
    }

     // Close Menu
    closeMenu() {
        if (!this.opened) return;
        this.opened = false;

        if (this.hasVideoTarget) {
            this.videoTimeoutId = setTimeout(() => {this.videoTarget.play();}, 1000); 
        }

        this.element.classList.remove(CLASS_OPENED);
        this.body.enableScroll();
    }
}
