const CLASS_CONTROL_DISABLED = 'is-disabled';

export default class Controls {
    initialisedNext = false;
    initialisedPrev = false;

    constructor(config) {
        this._slider = config.slider;
        this.nextItems = config.nextItems;
        this.prevItems = config.prevItems;
        this._next = config.next;
        this._prev = config.prev;
        this.update = this.update.bind(this);
        this._handleKeydown = this._handleKeydown.bind(this);

        if (typeof this.nextItems !== 'undefined') this.initialisedNext = true;
        if (typeof this.prevItems !== 'undefined') this.initialisedPrev = true;

        if (this.initialisedNext){
            for (let i = 0; i < this.nextItems.length; i++) {
                this.nextItems[i].addEventListener('click', this._next);
            }
        }

        if (this.initialisedPrev){
            for (let i = 0; i < this.prevItems.length; i++) {
                this.prevItems[i].addEventListener('click', this._prev);
            }
        }

        this._slider.addEventListener('keydown', this._handleKeydown);
    }

    _handleKeydown(e) {
        // console.log('keydown');
        if (e.keyCode === 37) {
            // console.log('left');
            this._prev();
        }

        if (e.keyCode === 39) {
            // console.log('right');
            this._next();
        }
    }

    update(showNext, showPrev) {
        if (this.initialisedNext){
            if (showNext) this._enable(this.nextItems);
            else this._disable(this.nextItems);
        }

        if (this.initialisedPrev){
            if (showPrev) this._enable(this.prevItems);
            else this._disable(this.prevItems);
        }
    }

    _disable(els) {
        for (let i = 0; i < els.length; i++) {
            els[i].classList.add(CLASS_CONTROL_DISABLED);
        }
    }

    _enable(els) {
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove(CLASS_CONTROL_DISABLED);
        }
    }
}