import "core-js/fn/promise";
import "core-js/fn/array/from";
import "core-js/fn/array/fill";

import "core-js/fn/array/find"
import "core-js/fn/array/find-index"
import "core-js/fn/map"
import "core-js/fn/object/assign"
import "core-js/fn/set"
// import "mutation-observer-inner-html-shim"
import "isomorphic-fetch"

import "element-closest/browser";

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

import smoothscroll from "smoothscroll-polyfill";
import "objectFitPolyfill";

smoothscroll.polyfill();
objectFitPolyfill();