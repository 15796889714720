import { Controller } from "stimulus";
import Body from './../components/body';

// Scrolled past hero and introduction
// For menu bg color change
const CLASS_INTRO_SCROLLED = 'is-home-intro-scrolled';

export default class extends Controller {
    static targets = ['hero', 'intro', 'scroll'];
    isIntroScrolled = false;
    introScrollHeight = null;

    initialize() {
        this.onScroll = this.onScroll.bind(this);
        this.tickHeight = this.tickHeight.bind(this);
    }

    connect() {
        this.body = new Body();
        this.body.addOnScroll(this.onScroll);
        this.tickHeight();
        window.addEventListener('load', this.tickHeight);
        window.addEventListener('resize', this.tickHeight);
    }

    tickHeight() {
        const heroHeight = this.heroTarget.clientHeight;
        const introHeight = this.introTarget.clientHeight;
        this.introScrollHeight = heroHeight + introHeight;
    }

    onScroll() {
        if (window.pageYOffset > this.introScrollHeight) this.introScrolled();
        else this.introUnscrolled();
    }

    scrollDown() {
        const top = this.introTarget.offsetTop;

        window.scrollTo({
            top: top < 0 ? 0 : top,
            behavior: 'smooth',
        });
    }

    introScrolled() {
        if (this.isIntroScrolled) return;
        this.isIntroScrolled = true;
        this.body.dom.classList.add(CLASS_INTRO_SCROLLED);
    }

    introUnscrolled() {
        if (!this.isIntroScrolled) return;
        this.isIntroScrolled = false;
        this.body.dom.classList.remove(CLASS_INTRO_SCROLLED);
    }
}
