/*  
    Version 1.0.4
    * Small improvements

    Version 1.0.3
    * Added precise - true / false (round last value)
    * Added alternative - 0 start from 0 window scroll
*/

import parseUnit from 'parse-unit';
import { isMobile } from './../../utils/is-mobile';
import { Rect } from './../../utils/rect';
import AF from './../../utils/AF';
import * as eases from './../../utils/ease-adv';

const ATTR_PARALLAX_CHILD = 'op-parallax-child';
const CLASS_ACTIVE = 'is-parallax-active';

export default class OpParallax {
    constructor(config) {
        if (isMobile) return; // Disable on Tablets & Mobile
        if (config.children.length === 0) return; // Disable if no items

        this.el = config.el;
        this.af = AF.instance();
        this.read = this.read.bind(this);
        this.write = this.write.bind(this);
        this.rect = new Rect({ el: this.el });
        this.children = config.children.map((childNode) => new Child(childNode));

        this.af.addRead(this.read);
        this.af.addWrite(this.write);
        this.af.onNextWrite(() => {
            this.el.classList.add(CLASS_ACTIVE);
        });
    }

    read() {
        this.rect.tick();
    }

    write() {
       for (let i = this.children.length - 1; i >= 0; i--) {
            this.children[i].animate(this.rect);
        }
    }
}

class Child {
    start = 0;
    end = 1;
    x = null;
    y = null;
    scale = null;
    opacity = null;
    ease = 'linear';
    progress = null;
    smallMult = 1000; // Multiplier for smaller values, i.e. opaicty - 0-1
    magic = 0.5;
    precise = false;
    finished = false;
    alternative = false;

    constructor(el) {
        this.el = el;
        const attr = JSON.parse(this.el.getAttribute(ATTR_PARALLAX_CHILD).replace(/'/g, '"'));

        if (attr.start) this.start = parseFloat(attr.start);
        if (attr.end) this.end = parseFloat(attr.end);
        if (attr.y) this.y = this.parse(attr.y);
        if (attr.x) this.x = this.parse(attr.x);
        if (attr.scale) this.scale = this.parse(attr.scale, true);
        if (attr.opacity) this.opacity = this.parse(attr.opacity, true);
        if (attr.ease) this.ease = attr.ease;
        if (attr.magic) this.magic = parseFloat(attr.magic);
        if (attr.precise === 'true') this.precise = true;
        if (attr.alternative === 'true') this.alternative = true;
    }

    animate(rect) {
        let progress;
        if (this.alternative) {
            const st = window.pageYOffset;
            const h = rect.height;
            progress = st/h;
        } else {
            progress = rect.progress;
        }

        const cleaned = Math.min(Math.max(progress, this.start), this.end); // start..end
        const percent = (cleaned - this.start) / (this.end - this.start); // 0 .. 1
        this.progress = eases[this.ease](percent); // 0 .. 1 - Eased
        
        let changed = false;

        if (this.y) changed = this.tick(this.y);
        if (this.x) changed = this.tick(this.x);
        if (this.scale) changed = this.tick(this.scale);
        if (this.opacity) changed = this.tick(this.opacity);

        if (changed) {
            this.render();
        }
    }

    tick(prop) {
        if (prop) {
            let value = prop.from + prop.distance * this.progress * prop.direction;

            if (this.precise) {
                value = Math.round(value); // If precise - end value needs to be round
            }

            if (prop.last === undefined) {
                prop.last = value;
                return true;
            } else {
                prop.last += (value - prop.last) * this.magic;
            }

            if (Math.abs(prop.last - value) < 0.2) {
                if (this.precise) {
                    if (!prop.precise) {
                        prop.last = value;
                        prop.precise = true;
                        return true; // One more iteration to set the value to be precise
                    }
                }

                return false; // If new value almost where it needs to be
            }

            prop.precise = false;
            return true;
        }

        return false;
    }

    render() {
        if (this.y || this.x || this.scale) {
            const y = this.y ? this.y.last + this.y.unit : 0;
            const x = this.x ? this.x.last + this.x.unit : 0;
            let string = '';

            if (this.y || this.x) {
                string += 'translate3d('+ x +','+ y +', 0) ';
            }

            if (this.scale) {
                let scale = this.scale.last/this.smallMult;
                scale = Math.round(scale * 10000) / 10000;
                string += 'scale3d('+scale+','+scale+','+scale+')';
            }

            this.el.style.transform = string.trim();
        }

        if (this.opacity) {
            let opacity = this.opacity.last/this.smallMult;
            opacity = Math.round(opacity * 1000) / 1000;
            this.el.style.opacity = opacity;
        }
    }

    parse(value, small = false) {
        if (!value) return false;
        let prop = {
            unit:  parseUnit(value[0])[1] || '',
            from:  parseUnit(value[0])[0],
            to:    parseUnit(value[1])[0],
        };

        if (small) prop.from = prop.from * this.smallMult;
        if (small) prop.to = prop.to * this.smallMult;

        prop.direction = prop.from > prop.to ? -1 : 1;
        prop.distance = Math.abs(prop.from - prop.to);
        prop.precise = true;
        return prop;
    }
}

