import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['subContent', 'sidebar'];

    sidebarSpacing = null;

    initialize() {
        this.tickSpacing = this.tickSpacing.bind(this);
    }

    connect() {
        // If Standard hero image is not present, no need to space out sidebar
        this.heroImage = this.element.querySelector('[data-standard-hero-image]');
        if (this.heroImage !== null) return;

        this.elementContent = this.element.querySelector('[data-element-content]');
        this.elementContentSidebar = this.elementContent.querySelector('[data-element-content-sidebar]');
        this.tickSpacing();
        window.addEventListener('load', this.tickSpacing);
        window.addEventListener('resize', this.tickSpacing);
    }

    tickSpacing() {
        // Height of Sub content
        const subContentHeight = this.subContentTarget.clientHeight;
        // Height of Hero sidebar
        const sidebarHeight = this.sidebarTarget.clientHeight;
        // Amount of spacing sidebar overflows down
        const sidebarSpacing = Math.max(sidebarHeight - subContentHeight, 0);

        // Checing if it changed (to not rerender each resize)
        if (sidebarSpacing != this.sidebarSpacing)  {
            this.sidebarSpacing = sidebarSpacing;
            this.elementContentSidebar.style.paddingTop = `${sidebarSpacing}px`;
        }
    }
}
