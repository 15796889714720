import { getX, getY, getType } from './_helpers';

export default class Events {
    _onDown = () => {};
    _onMove = () => {};
    _onUp = () => {};
    _disabled = false;

    mouse = {
        down: false,
        moved: false,
        type: '',
        xStart: 0,
        yStart: 0,
        xDist: 0,
        yDist: 0,
        tap: false,
    };

    constructor(config) {
        this._frame = config.frame;
        this._list = config.list;
        this._onDown = config.onDown;
        this._onMove = config.onMove;
        this._onUp = config.onUp;
        this._onResize = config.onResize;

        this._handleLinkClick = this._handleLinkClick.bind(this);
        this._handleDown = this._handleDown.bind(this);
        this._handleMove = this._handleMove.bind(this);
        this._handleUp = this._handleUp.bind(this);

        // if ('ontouchstart' in document.documentElement) {
            this._frame.addEventListener('touchstart', this._handleDown);
            this._frame.addEventListener('touchmove', this._handleMove);
            window.addEventListener('touchend', this._handleUp);
        // } else {
            this._frame.addEventListener('mousedown', this._handleDown);
            this._frame.addEventListener('mousemove', this._handleMove);
            window.addEventListener('mouseup', this._handleUp);
        // }

        this._list.addEventListener('click', this._handleLinkClick);
    }

    _handleLinkClick(e) {
        if (this._disabled) return;
        if (!this.mouse.tap) {
            e.preventDefault();
        }
    }

    disable() {
        this._disabled = true;
    }

    enable() {
        this._disabled = false;
    }

    _handleDown(e) {
        if (this._disabled) return;
        let { mouse: m, _onDown: _onD } = this;
        if (m.down) return;
        m.type = getType(e);
        m.down = true;
        m.moved = false;
        m.tap = false;
        m.xStart = getX(e);
        m.yStart = getY(e);
        _onD();
    }

    _handleMove(e) {
        // console.log(e, this._disabled);
        if (this._disabled) return;
        let { mouse: m, _onMove: _onM } = this;
        if (getType(e) !== m.type) return;

        m.xDist = (getX(e) - m.xStart); //* this.mult;
        m.yDist = getY(e) - m.yStart;
        // console.log(m.xDist);

        if (!m.moved && Math.abs(m.yDist) > Math.abs(m.xDist)) { 
            // If moved Y more than X (i.e scrolling)
            m.down = false;
        }

        if (m.down) e.preventDefault()
        if (!m.down) return;

        m.moved = true;
        _onM();
    }

    _handleUp(e) {
        if (this._disabled) return;
        let { mouse: m, _onUp: _onU } = this;
        if (!m.down) return;
        if (getType(e) !== m.type) return;
        m.down = false;
        m.xDist = getX(e) - m.xStart;

        if (Math.abs(m.xDist) < 5) {
            // Tap
            m.tap = true;
            return;
        } else e.preventDefault();

        _onU();
    }
}