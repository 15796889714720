import 'string.prototype.startswith';
import { isMobile } from './../utils/is-mobile';

const CLASS_FOCUSED = 'is-focused';
const CLASS_VALID = 'is-valid';
const CLASS_OPENED = 'is-opened';
const CLASS_HAS_VALUE = 'is-has-value';
const CLASS_OPTION_NOVALUE = 'is-option-no-value';
const CLASS_OPTION_SELECTED = 'is-option-selected';
const CLASS_SELECT_SIMPLE = 'is-select-simple';

export default class Select {
    opened = false;

    search = {
        char: "",
        started: false,
        timeout: null,
        delay: 300
    }

    constructor(props) {
        // Disable on Tablets & Mobile
        this.el = props.el;
        this.text = props.text;
        this.select = props.select;

        // Disable on Tablets & Mobile
        if (isMobile) {
            this.initMobile();
        } else {
            this.initDesktop();
        }
    }

    initMobile() {
        this.el.classList.add(CLASS_SELECT_SIMPLE);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.select.addEventListener('change', this.onChangeMobile);
        this.el.addEventListener("focus", this.onFocus);
        this.el.addEventListener('blur', this.onBlur);
        this.select.addEventListener("focus", this.onFocus);
        this.select.addEventListener('blur', this.onBlur);
        this.selectCurrentMobile();
    }

    initDesktop() {
        this.ul = document.createElement('div');
        this.ul.classList.add('select__ul');
        this.el.appendChild(this.ul);
        this.items = [].slice.call(this.select.options).map((option, index) => {
            const li = document.createElement('div');
            li.classList.add('select__li');
            li.innerHTML = option.text;
            this.ul.appendChild(li);
            if (!option.value) {
                li.classList.add(CLASS_OPTION_NOVALUE);
            }
            return {
                dom: li,
                index: index,
                value: option.value,
                text: option.text
            };
        });

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
        // this.changeEvent = new Event('change');

        this.changeEvent = document.createEvent('Event');
        this.changeEvent.initEvent('change', true, true);

        this.selectCurrent();
        this.text.addEventListener("click", this.open);
        this.el.addEventListener("focus", this.onFocus);
        this.el.addEventListener('blur', this.onBlur);
        this.el.addEventListener('keydown', this.onKeydown);

        this.items.forEach((item) => {
            item.dom.addEventListener("click", (e) => this.clickItem(item.index));
        });

        this.select.addEventListener('change', this.onChange);
    }

    onFocus() {
        this.focus();
        this.open();
    }

    onBlur() {
        this.unfocus();
        this.close();
    }   

    onChangeMobile() {
        this.selectCurrentMobile();
    }

    selectCurrentMobile() {
        if (this.select.options[this.select.selectedIndex].value) {
            this.text.innerHTML = this.select.options[this.select.selectedIndex].text;
            this.el.classList.add(CLASS_HAS_VALUE);
        } else {
            this.text.innerHTML = '';
            this.el.classList.remove(CLASS_HAS_VALUE);
        }
    }

    onChange() {
        this.selectCurrent();
    }

    onKeydown(e) {
        // Esc keydowm
        if (e.which === 27) {
            this.onEsc(e);
            return;
        }
        // input was 0-9 // input was a-z
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
            this.onChar(e);
            return;
        }

        // arrows
        if (e.which === 38 || e.which === 40) {
            e.preventDefault();
            this.onArrow(e);
            return;
        }

        // enter
        if (e.which === 13) {
            this.onEnter(e);
            return;
        }
    }

    onEsc(e) {
        e.preventDefault();
        this.close();
    }

    onChar(e) {
        if (!this.search.started) {
            this.search.started = true;
        } else {
            window.clearTimeout(this.search.timeout);
        }

        this.search.timeout = window.setTimeout(() => {
            this.search.char = "";
            this.search.started = false;
        }, this.search.delay);
        
        this.search.char += e.key;
        this.searchChar();
    }

    onArrow(e) {
        if (!this.opened) {
            this.open();
            return;
        }

        // Up
        if (e.keyCode === 38) {
            this.select.selectedIndex = Math.max(this.select.selectedIndex - 1, 0);
            this.select.dispatchEvent(this.changeEvent);
            this.scrollToSelected();
        } 

        // Down
        else if (e.keyCode === 40) {
            this.select.selectedIndex = Math.min(this.select.selectedIndex + 1, this.items.length - 1);
            this.select.dispatchEvent(this.changeEvent);
            this.scrollToSelected();
        }
    }

    onEnter(e) {
        e.preventDefault();
        if (!this.opened) {
            this.open();
        } else {
            this.close();
        }
    }

    searchChar() {
        if (this.search.char === "") return;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].text.toLowerCase().startsWith(this.search.char.toLowerCase())) {
                // console.log(this.items[i].text);
                this.select.selectedIndex = i;
                this.select.dispatchEvent(this.changeEvent);
                this.scrollToSelected();
                break;
            }
        }
    }

    selectCurrent() {
        if (this.items[this.select.selectedIndex].value) {
            this.text.innerHTML = this.items[this.select.selectedIndex].text;
            this.el.classList.add(CLASS_HAS_VALUE);
        } else {
            this.text.innerHTML = '';
            this.el.classList.remove(CLASS_HAS_VALUE);
        }

        // Add class to selected option in dropdown
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].dom.classList.remove(CLASS_OPTION_SELECTED);
        }
        this.items[this.select.selectedIndex].dom.classList.add(CLASS_OPTION_SELECTED);
    }

    clickItem(index) {
        this.select.selectedIndex = index;
        this.select.dispatchEvent(this.changeEvent);
        this.close();
    }

    focus() {
        this.el.classList.add(CLASS_FOCUSED);
    }

    unfocus() {
        this.el.classList.remove(CLASS_FOCUSED);
    }

    open() {
        if (this.opened) return;
        this.opened = true;
        this.ul.classList.add(CLASS_OPENED);
        this.scrollToSelected();
    }

    close() {
        if (!this.opened) return;
        this.opened = false;
        this.ul.scrollTop = 0; // Important for Firefox
        this.ul.classList.remove(CLASS_OPENED);
    }

    scrollToSelected() {
        if (!this.opened) return;
        this.ul.scrollTop = 0;
        const ulRect = this.ul.getBoundingClientRect();
        const liRect = this.items[this.select.selectedIndex].dom.getBoundingClientRect();
        const scrollDist = liRect.top - ulRect.top - ulRect.height/2 + liRect.height/2;
        this.ul.scrollTop = scrollDist < 0 ? 0 : scrollDist;
        
    }
}