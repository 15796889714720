import { Controller } from "stimulus";
const CLASS_HOVERED = 'is-links-hovered';

export default class extends Controller {

    onOver() {
        if (this.hovered) return;
        this.hovered = true;
        this.element.classList.add(CLASS_HOVERED);
    }

    onOut() {
        if (!this.hovered) return;
        this.hovered = false;
        this.element.classList.remove(CLASS_HOVERED);
    }
}
