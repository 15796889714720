import { Controller } from "stimulus";

const CLASS_OPENED = 'is-search-opened';
const CLASS_FOCUSED = 'is-search-focused';
const CLASS_HOVERED = 'is-links-hovered';

export default class extends Controller {
    static targets = ['search', 'input', 'links'];
    opened = false;
    focused = false;
    hovered = false;

    initialize() {
        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    connect() {
        document.addEventListener('click', this.outsideClickListener);
    }

    outsideClickListener(e) {
        if (!this.searchTarget.contains(e.target)) {
            this.searchClose();
        }
    }

    onOver() {
        if (this.hovered) return;
        this.hovered = true;
        this.element.classList.add(CLASS_HOVERED);
    }

    onOut() {
        if (!this.hovered) return;
        this.hovered = false;
        this.element.classList.remove(CLASS_HOVERED);
    }

    searchOpen() {
        if (this.opened) return;
        this.opened = true;
        this.inputTarget.focus();
        this.element.classList.add(CLASS_OPENED);
    }

    searchClose() {
        if (!this.opened) return;
        this.opened = false;
        this.element.classList.remove(CLASS_OPENED);
    }

    searchFocus() {
        if (this.focused) return;
        this.focused = true;
        this.searchTarget.classList.add(CLASS_FOCUSED);
    }

    searchBlur() {
        if (!this.focused) return;
        this.focused = false;
        this.searchTarget.classList.remove(CLASS_FOCUSED);
    }
}
