import { Controller } from "stimulus";
import OpParallax from "./../components/opParallax/opParallax";

export default class extends Controller {
    static targets = [
        "item",
    ];

    connect() {
        if (this.hasItemTarget) {
            new OpParallax({
                el: this.element,
                children: this.itemTargets,
            });
        }
    }
}
