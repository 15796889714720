import { Controller } from "stimulus";

export default class extends Controller {

    back() {
        window.scrollTo({
            top: 0
        });
    }
}
