export class Rect {
    constructor({el}) {
        this.el = el;
        this.tick();
    }

    tick() {
        var rect = this.el.getBoundingClientRect();
        this.top    = rect.top;
        this.right  = rect.right;
        this.bottom = rect.bottom;
        this.left   = rect.left;
        this.height = rect.height;
        this.width  = rect.width;
        this.winHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }

    get progress() {
        return (1 - (this.top + this.height) / (this.winHeight + this.height));
    }

    get altProgress() {
        return (1 - (this.top + this.height) / this.height);
    }
}
