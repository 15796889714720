import { Controller } from "stimulus";

const CLASS_OPENED = 'is-dropdown-opened';

export default class extends Controller {
    static targets = ["body", "content"];
    resizeTimer = null;

    initialize() {
        this.onResize = this.onResize.bind(this);
        this.tick = this.tick.bind(this);
    }

    connect() {
        if (this.hasBodyTarget) {
            window.addEventListener("load", this.tick);
            window.addEventListener("resize", this.onResize);
            this.tick();
        }
    }

    onResize() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.tick, 100);
    }

    tick() {
        const rect = this.contentTarget.getBoundingClientRect();
        this.bodyTarget.style.height = `${rect.height}px`;
    }

    toggle() {
        this.element.classList.toggle(CLASS_OPENED);
    }
}
