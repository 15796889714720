/*
    Body class, for manipulations on the body.
    Reusing one instance.
*/
const CLASS_BODY_DISABLED = 'is-scroll-disabled';

export default class Body {
    _scrollDisabled = false;
    _onScroll = [];
    _instance;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor(){
        this.dom = document.body;
        this._handleScroll = this._handleScroll.bind(this);
        window.addEventListener('scroll', this._handleScroll);
    }

    addOnScroll(fn){
        this._onScroll.push(fn);
        fn();
    }

    _handleScroll() {
        const { _onScroll: oS } = this;
        for(let i = 0; i < oS.length; i++) oS[i]();
    }

    disableScroll() {
        if(this._scrollDisabled) return;
        this._scrollDisabled = true;
        this.dom.classList.add(CLASS_BODY_DISABLED);
    }

    enableScroll() {
        if(!this._scrollDisabled) return;
        this._scrollDisabled = false;
        this.dom.classList.remove(CLASS_BODY_DISABLED);
    }
}