import { Controller } from "stimulus";
import AF from './../utils/AF';
import mouse from './../utils/mouse';

export default class extends Controller {
    static targets = ['bg', 'fg'];
    progress = 0;

    initialize() {
        this.mousemove = this.mousemove.bind(this);
        this.write = this.write.bind(this);
        this.af = AF.instance();
    }

    connect() {
        // this.paths = [].slice.call(this.element.querySelectorAll('path')).map((path) => {
        //     path.style.strokeDasharray = `${path.getTotalLength()}`;
        //     path.style.strokeDashoffset = `${path.getTotalLength()}`;

        //     return {
        //         dom: path,
        //         length: path.getTotalLength()
        //     }
        // });

        // console.log(this.paths);

        // window.addEventListener('mousemove', this.mousemove);
        // this.af.addWrite(this.write);
    }

    mousemove(e){
        const { clientX: x, clientY: y } = e;
        const { innerWidth: w, innerHeight: h } = window;
        this.x = x / w * 2 - 1;
        this.y = y / h * 2 - 1;
    }

    write() {
        // if (Math.abs(this.progress - 1) < 0.001) return;

        let ty = this.y * 10;
        let tx = this.x * 10;
        this.fgTarget.style.transform = `translate3d(${tx}px, ${ty}px, 0)`;

        this.bgTarget.style.transform = `translate3d(${tx/2}px, ${ty/2}px, 0)`;


        // this.paths.forEach((path) => {
        //     path.dom.style.strokeDashoffset = `${path.length * (1 - this.progress)}`
        // });
    }
}
