import { Controller } from "stimulus";
import Flatpickr from "flatpickr";
import Validate from "validate.js";

const CLASS_INVALID = 'is-field-invalid';
const CLASS_VERIFIED = 'is-field-verified';
const CLASS_FOCUSED = 'is-field-focused';
const CLASS_HIDDEN = 'is-field-hidden';
const CLASS_SUCCESS = 'is-form-success';
const CLASS_RESPONSE_SHOW = 'is-response-shown';

export default class extends Controller {
    static targets = ['col', 'successMsg', 'failMsg', 'customMsg'];
    items = [];

    disableValidation = false;

    errorMsgs = [
        {type: 'default', msg: 'This field is invalid'},
        {type: 'required', msg: 'This field is required'},
        {type: 'email', msg: 'Please enter a valid email address'},
        {type: 'email-verify', msg: 'The email addresses you\'ve provided don\'t match'},
        {type: 'start-date', msg: 'Please provide start date'},
        {type: 'end-date', msg: 'End date should be later than start date'}
    ];

    connect() {
        this.url = this.element.getAttribute('action');
        this.method = this.element.getAttribute('method') || 'post';
        this.dates = [].slice.call(this.element.querySelectorAll('input[type="date"]'));

        if (this.dates.length > 0) {
            this.dates.forEach(date => Flatpickr(date, {minDate: new Date()}));
        }

        this.colTargets.forEach(col => this.init(col));
        this.element.addEventListener('submit', this.submit.bind(this));

        // For triggering select change
        this.changeEvent = document.createEvent('Event');
        this.changeEvent.initEvent('change', true, true);
    }

    init(col) {
        const field = col.querySelector('input') || col.querySelector('select') || col.querySelector('textarea');
        const attr = col.getAttribute('data-validate');

        // If has validate attribute
        const item = {
            col: col, 
            field: field,
            attr: attr || null,
            valid: attr ? 0 : 1, // -1 - showing the error, 0 - not validated yet, 1 - valid
            msg: attr ? this.createErrorMsg(attr) : ''
        };
        this.items.push(item);
        field.addEventListener('focus', this.onFocus.bind(this, item));
        field.addEventListener('blur', this.onUnfocus.bind(this, item));

        if (attr) {
            field.addEventListener('blur', this.onBlur.bind(this, item));
            item.msg.addEventListener('click', this.focus.bind(this, item)); // Error msg focus

            if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {
                field.addEventListener('input', this.onInput.bind(this, item));
            }

            if (field.tagName === 'SELECT') {
                field.addEventListener('change', this.onChange.bind(this, item));

                // Label click trigger focus
                const label = col.querySelector('label');

                if (label) {
                    label.addEventListener('click', this.focus.bind(this, item));
                }
            }
        }
    }

    createErrorMsg(attr){
        const msg = document.createElement('span');
        msg.classList.add('form__error');

        let index = this.errorMsgs.findIndex(msg => msg.type === attr);
        if (index === -1) index = 0;

        msg.innerHTML = this.errorMsgs[index].msg;
        return msg;
    }

    onFocus(item) {
        item.col.classList.add(CLASS_FOCUSED);
    }

    onUnfocus(item) {
        item.col.classList.remove(CLASS_FOCUSED);
    }

    onBlur(item) {
        this.validate(item);
        // console.log('blur', item);
    }

    focus(item) {
        const field = item.field;

        if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {
            field.focus(); 
        }

        if (field.tagName === 'SELECT') {
            const select = item.col.querySelector('.select');
            select.focus();
        }
    }

    onInput(item) {
        if (item.valid < 0 || item.attr === 'start-date' || item.attr === 'end-date') {
            this.validate(item);
        }
        // console.log('input', item);
    }

    onChange(item) {
        this.validate(item);
        // console.log('change', item);
    }

    submit(e) {
        e.preventDefault();
        this.resetMsgs();

        // Validate all
        this.items.forEach(item => this.validate(item));

        // Find first not valid item
        let index = this.items.findIndex(item => item.valid < 0);

        // If everything is valid
        if (index === -1) {
            this.send();
        } else {
            this.focus(this.items[index]);
        }
    }

    resetMsgs() {
        this.successMsgTarget.classList.remove(CLASS_RESPONSE_SHOW);
        this.failMsgTarget.classList.remove(CLASS_RESPONSE_SHOW);
        this.clearCustomMsg();
    }

    send() {
        const data = new FormData(this.element);

        fetch(this.url, {
            method: this.method,
            body: data,
            credentials: 'same-origin',
            headers: {
                'Accept' : 'application/json',
                'x-requested-with' : 'XMLHttpRequest'
            }
        })
        .then((response) => {
            if (response.ok) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        })
        .then(response => response.json())
        .then((response) => {
            this.success(response);
        }, (response) => {
            this.fail(response);
        });
    }

    fail(response) {
        console.log('Fail', response);
        this.failMsgTarget.classList.add(CLASS_RESPONSE_SHOW);
    }

    success(response) {
        console.log('Success', response);

        this.element.classList.add(CLASS_SUCCESS);
        
        // Reset form fields
        this.reset();

        // Add custom message
        if (response.form) {
            this.fillCustomMsg(response.form, response.translationGuide);
        }

        // Show sucess msg
        this.successMsgTarget.classList.add(CLASS_RESPONSE_SHOW);

        // Scroll up
        const top = this.element.offsetTop - 50;
        window.scrollTo({
            top: top < 0 ? 0 : top,
            behavior: 'smooth',
        });
    }

    fillCustomMsg(form, translationGuide) {
        let links = '';
        links += `<div class="form__cta-item"><h3><b>Download Application Form</b></h3> <a href="${form.URL}" download="${form.Title}.pdf"><h5>${form.Title}</h5></a></div>`
        if(translationGuide) {
            links += `<div class="form__cta-item"><h3><b>Download Translation Guide</b></h3> <a href="${translationGuide.URL}" download="${translationGuide.Title}.pdf"><h5>${translationGuide.Title}</h5></a></div>`
        }
        this.customMsgTarget.innerHTML = `<div class="form__cta">${links}</div>`;
    }

    clearCustomMsg() {
        this.customMsgTarget.innerHTML = '';
    }

    reset() {
        this.disableValidation = true;
        this.items.forEach(item => this.clear(item));
        this.disableValidation = false;
    }

    clear(item) {
        if (item.valid < 0) {
            item.col.removeChild(item.msg);
        }

        item.valid = 0;
        if (item.field.tagName === 'SELECT') {
            item.field.selectedIndex = 0;
            item.field.dispatchEvent(this.changeEvent);
        } else {
            item.field.value = '';
        }

        item.col.classList.remove(CLASS_VERIFIED);
        item.col.classList.remove(CLASS_INVALID);
    }

    validate(item) {
        if (this.disableValidation) return;
        let valid;

        if (item.attr === 'required' || 
            item.attr === 'start-date' ||
            item.attr === 'school') {
            valid = !Validate.isEmpty(item.field.value) ? 1 : -1;
        }

        if (item.attr === 'email') {
            // console.log(item.field.value);
            const constraints = {
                from: {
                    email: true
                }
            };

            valid = typeof Validate({from: item.field.value}, constraints) === 'undefined' ? 1 : -1;
        }

        if (item.attr === 'email-verify') {
            const constraints = {
                verifyEmail: {
                    equality: "email"
                }
            };
            const index = this.items.findIndex(item => item.attr === 'email');
            
            if (index !== -1) {
                const email = this.items[index];
                valid = typeof Validate({email: email.field.value, verifyEmail: item.field.value}, constraints) === 'undefined' ? 1 : -1;
            }
        }

        if (item.attr === 'start-date') {
            const index = this.items.findIndex(item => item.attr === 'end-date');
            
            if (index !== -1) {
                const end = this.items[index];

                // Only if end-date was changed before
                if (end.valid !== 0) this.validate(end);
            }
        }

        if (item.attr === 'end-date') {
            const index = this.items.findIndex(item => item.attr === 'start-date');
            
            if (index !== -1) {
                const start = this.items[index];
                const startDate = new Date(start.field.value).getTime();
                const endDate = new Date(item.field.value).getTime()

                valid = endDate >= startDate ? 1 : -1;
                
                // console.log(startDate, endDate, valid);
            }
        }

        if (item.attr === 'school') {
            const index = this.items.findIndex(item => item.attr === 'school-other');
            
            if (index !== -1) {
                const other = this.items[index];

                // Show other school input, if selected 'other'
                if (item.field.value === 'other') {
                    // console.log('other');
                    this.show(other);
                } else {
                    this.hide(other);
                }
            }
        }

        if (item.attr === 'school-other') {
            const index = this.items.findIndex(item => item.attr === 'school');
            
            if (index !== -1) {
                const school = this.items[index];

                // Only validate, if selected 'other'
                if (school.field.value === 'other') {
                    valid = !Validate.isEmpty(item.field.value) ? 1 : -1;
                } else {
                    // otherwise set to 0
                    valid = 0;
                }
            }
        }

        if (valid < 0) this.error(item);
        if (valid > 0) this.verify(item);
        if (valid === 0) item.valid = 0;
    }

    show(item) {
        item.col.parentNode.classList.remove(CLASS_HIDDEN);
    }

    hide(item) {
        item.col.parentNode.classList.add(CLASS_HIDDEN);
    }

    error(item) {
        // If already showing error
        if (item.valid < 0) return;
        item.valid = -1;
        item.col.classList.add(CLASS_INVALID);
        item.col.classList.remove(CLASS_VERIFIED);
        item.col.appendChild(item.msg);
    }

    verify(item) {
        // If already verified
        if (item.valid > 0) return;

        // If was invalid before
        if (item.valid < 0) {
            item.col.removeChild(item.msg);
            item.col.classList.remove(CLASS_INVALID);
        }

        item.valid = 1;
        item.col.classList.add(CLASS_VERIFIED);
    }
}
