import { Controller } from "stimulus";
import OpSlider from "./../components/opSlider/opSlider";

export default class extends Controller {
    resizeTimer = null;

    static targets = [
        "frame",
        "list",
        "dots",
        "slide"
    ];
    
    initialize() {
        this.onResize = this.onResize.bind(this);
        this.tick = this.tick.bind(this);
    }

    connect() {
        let config = {
            slider: this.element,
            frame: this.frameTarget,
            list: this.listTarget,
            dots: this.dotsTarget,
            slides: this.slideTargets,
            disabled: true,
            mode: {
                fill: true
            }
        };

        this.slider = new OpSlider(config);
        this.tick();
        window.addEventListener('resize', this.onResize);
    }

    onResize() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.tick, 250);
    }

    tick() {
        if (window.innerWidth > 600) {
            this.slider.disable();
        } else {
            this.slider.enable();
        }
    }
}
