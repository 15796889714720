// src/controllers/tile_controller.js
import { Controller } from "stimulus"
import mouse from './../utils/mouse';
import AF from './../utils/AF';
import { isMobile } from './../utils/is-mobile';

const CLASS_MOUSE_OVER = 'is-mouse-over';

export default class extends Controller {
    static targets = ['text', 'image'];

    x = 0;
    y = 0;

    oldX = null;
    oldY = null;

    animX = 0;
    animY = 0;

    stopped = true;
    mouseOver = false;
    resizeTimer = null;
    rect = null;
    
    initialize() {
        this.onMove = this.onMove.bind(this);
        this.onLeave = this.onLeave.bind(this);
        this.onResize = this.onResize.bind(this);
        this.loop = this.loop.bind(this);
        this.tick = this.tick.bind(this);
    }

    connect() {
        if (isMobile) return;
        this.af = AF.instance();

        this.tick();
        window.addEventListener('resize', this.onResize);
        window.addEventListener("load", this.onResize);

        this.element.addEventListener('mousemove', this.onMove);
        this.element.addEventListener('mouseleave', this.onLeave);
        this.resetCoord();
        this.af.addWrite(this.loop);
    }

    onLeave() {
        this.af.onNextWrite(() => {            
            // this.textTarget.style.transform = '';
            // this.imageTarget.style.transform = '';
            // this.animX = 0;
            // this.animY = 0;
            this.stop();
            this.element.classList.remove(CLASS_MOUSE_OVER);
            this.mouseOver = false;
            // this.af.removeWrite(this.loop);
        });
    }

    onMove(e) {
        if (!this.mouseOver) {
            this.mouseOver = true;
        }

        if (this.stopped) {
            this.element.classList.add(CLASS_MOUSE_OVER);
        }

        this.stopped = false;
        this.x = mouse(e, this.element).x; //x position within the element
        this.y = mouse(e, this.element).y; //x position within the element
    }

    onResize() {
        let scope = this;
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(function() {
            // Run code here, resizing has "stopped"
            scope.af.onNextRead(scope.tick);
        }, 250);
    }

    tick() {
        this.rect = this.element.getBoundingClientRect();
    }

    stop() {
        this.stopped = true;
        this.resetCoord();
    }

    resetCoord() {
        this.x = this.rect.width/2;
        this.y = this.rect.height/2;
    }

    loop() {
        // console.log();
        if (
            (
                // (this.oldX !== this.x && this.oldY !== this.y) ||
                // (
                    Math.abs(this.animX - this.x - this.rect.width/2) > 0.02 || 
                    Math.abs(this.animY - this.y - this.rect.height/2) > 0.02
                // )
            )
        ) {
            // console.log(this.animX, this.x - this.rect.width/2, this.animY, this.y - this.rect.height/2);
            // console.log(this.x, this.rect.width);
            this.oldX = this.x;
            this.oldY = this.y;

            this.animX += (this.x - this.animX - this.rect.width/2) * 0.06;
            this.animY += (this.y - this.animY - this.rect.height/2) * 0.06;

            // console.log('animating', this.animX, this.x, 'y', this.animY, this.y);

            let rotateX = (this.animY) / (this.rect.width / 6);
            let rotateY = -(this.animX) / (this.rect.width / 6);

            let tranX = (this.animX) / (this.rect.width / 2);
            let tranY = (this.animY) / (this.rect.width / 2);

            rotateX = Math.round(rotateX * 1000) / 1000;
            rotateY = Math.round(rotateY * 1000) / 1000;
            tranX = Math.round(tranX * 1000) / 1000;
            tranY = Math.round(tranY * 1000) / 1000;

            this.textTarget.style.transform = `translate3d(${tranX}%, 0, 0)`;
            this.imageTarget.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) translate3d(${tranX}%, ${tranY}%, 0)`; 
        }
    }
}