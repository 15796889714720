import { Controller } from "stimulus";
import OpSlider from "./../components/opSlider/opSlider";

const CLASS_SELECTED = "is-item-selected";
const CLASS_PREV = "is-item-previous";
const CLASS_LEFT = "is-dir-left";
const CLASS_RIGHT = "is-dir-right";

export default class extends Controller {
    currentIndex = null;
    prevIndex = 0;
    resizeTimer = null;
    disableTimer = null;
    disabled = false;

    static targets = [
        "slider",
        "frame",
        "list",
        "imageSlide",
        "left",
        "right",
        "index",
        "h",
        "p"
    ];

    initialize() {
        this.onLoad = this.onLoad.bind(this);
        this.select = this.select.bind(this);
        this.onResize = this.onResize.bind(this);
        this.tick = this.tick.bind(this);
    }

    connect() {
        this.length = this.imageSlideTargets.length;
        window.addEventListener("load", this.onLoad);
        window.addEventListener('resize', this.onResize);
    }

    tick() {
        // this.select(0);

        if (window.innerWidth > 480) {
            this.slider.disableDrag();
        } else {
            this.slider.enableDrag();
        }
    }

    onResize() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.tick, 250);
    }

    onLoad() {
        let config = {
            slider: this.sliderTarget,
            frame: this.frameTarget,
            list: this.listTarget,
            slides: this.imageSlideTargets,
            nextItems: [this.rightTarget],
            prevItems: [this.leftTarget],
            onSelect: this.select,
            isStatic: true,
            dragAllowed: true,
            mode: {}
        };

        this.slider = new OpSlider(config);
        this.tick();
    }

    select(index) {
        if (this.disabled) return;
        if (this.currentIndex === index) return;

        clearTimeout(this.disableTimer);
        this.disabled = true;
        this.slider.disable();
        this.disableTimer = setTimeout(() => {
            this.disabled = false;
            this.slider.enable();
        }, 1000);

        this.prevIndex = this.currentIndex;
        this.currentIndex = index;

        if (this.prevIndex > this.currentIndex) {
            this.element.classList.add(CLASS_RIGHT);
            this.element.classList.remove(CLASS_LEFT);
        } else {
            this.element.classList.add(CLASS_LEFT);
            this.element.classList.remove(CLASS_RIGHT);
        }


        for (let i = 0; i < this.length; i++) {
            if (i === this.currentIndex) {
                this.applyClass(CLASS_SELECTED, i);
                this.removeClass(CLASS_PREV, i);
            } else if (i === this.prevIndex) {
                this.removeClass(CLASS_SELECTED, i);
                this.applyClass(CLASS_PREV, i);
            } else {
                this.removeClass(CLASS_SELECTED, i);
                this.removeClass(CLASS_PREV, i);
            }
        }
    }

    applyClass(className, index) {
        this.imageSlideTargets[index].classList.add(className);
        this.hTargets[index].classList.add(className);
        this.pTargets[index].classList.add(className);
        this.indexTargets[index].classList.add(className);
    }

    removeClass(className, index) {
        this.imageSlideTargets[index].classList.remove(className);
        this.hTargets[index].classList.remove(className);
        this.pTargets[index].classList.remove(className);
        this.indexTargets[index].classList.remove(className);
    }
}
