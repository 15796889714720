import { Controller } from "stimulus";
import Select from './../components/select';

export default class extends Controller {
    static targets = ['text', 'select'];

    connect() {
        new Select({
            el: this.element,
            text: this.textTarget,
            select: this.selectTarget,
        });
    }
}
