import { Controller } from "stimulus"

import AF from './../utils/AF';
import { Rect } from './../utils/rect';

const CLASS_INVIEW = 'is-inview';
const DEFAULT_THRESHOLD_START = 0.2;
const DEFAULT_THRESHOLD_END = 0.8;

export default class extends Controller {
    last = null;

    initialize() {
        this.af = AF.instance();
        this.read = this.read.bind(this);
        this.write = this.write.bind(this);
    }

    connect() {
        this.hasDeactivate = this.element.hasAttribute('op-inview-deactivate');
        this.start = DEFAULT_THRESHOLD_START;
        this.end = DEFAULT_THRESHOLD_END;
        this.class = CLASS_INVIEW;
        this.rect = new Rect({ el: this.element });

        const settings = this.element.getAttribute('op-inview');

        if (settings){
            const values = settings.split(',');
            this.start = parseFloat(values[0]);
            this.end = parseFloat(values[1]);

            if (values[2]) {
                this.class = values[2].trim();
            }
        }
        
        this.af.addRead(this.read);
        this.af.addWrite(this.write);
    }

    read() {
        this.rect.tick();
    }

    write() {
        if (this.rect.progress >= this.start /*&& this.rect.progress <= this.end*/) {
            this.activate();
        } else {
            if (this.hasDeactivate) {
                this.deactivate();
            }
        }
    }

    activate() {
        if (!this.hasDeactivate) {
            this.af.removeRead(this.read);
            this.af.removeWrite(this.write);
        }

        this.af.onNextWrite(() => {
            this.element.classList.add(this.class);
        });
    }

    deactivate() {
        this.af.onNextWrite(() => {
            this.element.classList.remove(this.class);
        });
    }
}