import { Controller } from "stimulus";
import wrap from 'word-wrap';

export default class extends Controller {
    connect() {
        let length = parseInt(this.element.getAttribute('word-wrap'));
        let indent = this.element.getAttribute('word-wrap-indent') || '';
        let newline = this.element.getAttribute('word-wrap-newline') || '<br/>';

        this.element.innerHTML = wrap(this.element.innerHTML.trim(), {
            width: length,
            trim: true,
            escape: function(string){
                return String(indent) + string.trim() + String(newline);
            }
        });
    }
}