import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        this.tables = [].slice.call(this.element.querySelectorAll('table'));

        if (this.tables.length > 0) {
            this.tables.forEach(table => this.transform(table));
        }
    }

    transform(table) {
        const trs = [].slice.call(table.querySelectorAll('tr'));
        const labeltds = [].slice.call(trs[0].querySelectorAll('td'));
        const labels = labeltds.map(td => td.textContent);

        for (let i = 1; i < trs.length; i++) {
            const tds = [].slice.call(trs[i].querySelectorAll('td'));
            tds.forEach((td, index) => td.setAttribute('data-label', labels[index]));
        }
        // console.log(labels);
    }
}
