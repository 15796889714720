
export default class Autoplay {
    timer = null;
    stopped = true;
    initialised = false;

    constructor(config) {
        if (typeof config.el === 'undefined') return;
        this._callback = config.callback;
        this._settings = config.settings;

        this.duration = this._settings.duration || 3000;
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.restart = this.restart.bind(this);
    }

    start() {
        if (!this.initialised) return;
        if (!this.stopped) return;

        // console.log('start Autoplay');
        clearTimeout(this.timer);

        this.stopped = false;
        this.timer = setTimeout(() => {
            // console.log('callback Autoplay', this.duration);
            this.stopped = true;
            this._callback();
            this.start();
        }, this.duration);
    }

    stop() {
        if (!this.initialised) return;
        if (this.stopped) return;
        this.stopped = true;
        clearTimeout(this.timer);
    }

    restart(){
        this.stop();
        this.start();
    }
}