const CLASS_DOT_ACTIVE = 'is-active';

export default class Dots {
    initialised = false;

    constructor(config) {
        if (typeof config.el === 'undefined') return;
        this.initialised = true;
        this.el = config.el;
        this.length = config.length;
        this.select = this.select.bind(this);
        this.items = [];

        for (let i = 0; i < this.length; i++) {
            this.items[i] = document.createElement('li');
            this.items[i].addEventListener('click', () => config.onClick(i));
            this.el.appendChild(this.items[i]);
        }
    }

    select(index) {
        if (!this.initialised) return;
        for (let i = 0; i < this.length; i++) {
            this.items[i].classList.remove(CLASS_DOT_ACTIVE);
        }
        this.items[index].classList.add(CLASS_DOT_ACTIVE);
    }
}